.container {
    background: #EEEEEE;
    border-radius: 0px 0px 10px 10px;
    padding: 15px 20px;
    margin: 10px 0;
}

.container > div:first-child > div:first-child > div:first-child > div:first-child > div {
    padding-bottom: 4px;
}

.container > div:first-child > div:first-child > div:first-child > div:first-child > div > div[aria-selected='true'] {
    padding-bottom: 4px;
    color: #4285F4;
}

.container > div:first-child > div:first-child > div:first-child > div:first-child > div:last-child {
    padding-bottom: unset !important;
    /*width: 80px !important;*/
    height: 3px !important;
    background-color: #4285F4;
}

.container > div > div > div > div > div > div {
    font-family: MarkPro;
}

.chartLogos {
    display: flex;
}

.chartLogo {
    margin-right: 20px;
    padding: 5px;
    width: 50px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chartLogoSelected {
    background-color: #FFFFFF;
}

.donutExtraOptions {

}

.showOptions {

}

.sliderOption {
    display: flex;
}

.sliderOption p {
    margin-left: 10px;
}


/*color tab*/

.colorContainer {
    display: flex;
}

.colorContainer p {
    margin-bottom: unset;
}

.colorCategory {
    padding-right: 10px;
    border-right: 1px solid #999999;
    width: 50%;
}

p.colorTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #999999;
    margin-bottom: 14px;
}

.colorBody {

}

.palette {
    flex: 1 1;
    padding-left: 10px;
}

.color {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.color:hover {
    cursor: pointer;
}

.color p {
    margin-left: 10px;
}

.selectedColor {
    background-color: yellow;
}


/*display tab*/

.displayOptions {

}

.displayOptions p {
    margin-bottom: unset;
}

.displayCategory {
    margin-bottom: 20px;
}

p.displayTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #999999;
    margin-bottom: 14px;
}

.displayBody {

}

.displayTableBody {
    display: flex;
}

.toggleItemBody {
    margin-right: 30px;
    margin-bottom: 20px;
}

.displayTableBody p {
    margin-left: 10px;
}


/*labels tab*/

.labelsBody {
    display: flex;
}

.labelsBody p {
    margin-left: 10px;
}

.radarLogo svg {
    width: 24px;
    height: 24px;
    fill: #999999;
}

.horizontal {
    transform: rotate(90deg);
}

.logoResize {
    width: 24px;
    height: 24px;
}

.logoResize path {
    fill: rgb(153, 153, 153);
}

.starLogo {
    height: 24px;
}

.starLogo path {
    fill: rgb(153, 153, 153);
}

.displayCategoryGroup {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
}


.hidden {
    display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700;800&display=swap");

:root {
  --primary-black-color: #222222;
  --primary-red-color: #ce375c;
  --primary-background-color: #EEEEEE;
  --off-white-color: #E0E0E0;

  --primary-text-black: #2a3342;
  --secondary-text-grey: #556987;
  --primary-text-grey: #616161;


  --secondary-text-black: #6D7175;

  --grey-dark: #4f4f4f;
  --success: #34a853;
  --primary: #4285f4;


}

body {
  margin: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background-color);
  max-height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

p {
  margin: unset;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

a {
  color: var(--primary-red-color);
}

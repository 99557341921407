.select {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    width: 205px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E5E0EB !important;
    box-sizing: border-box;
    border-radius: 2px;
}

.select > div {
    border-radius: 2px !important;
    border: 1px solid #E5E0EB !important;
}

.demo-inline {
    /*box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);*/
    text-align: left;
    color: #626262;
    line-height: 1.3;
    font-size: 14px;
    background-color: #ffffff;
    text-align: left;
    vertical-align: top;
    padding: 20px 20px 20px 20px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
}
.demo-inline .container {
    background-color: #fafafa;
    margin: -20px -20px 0 -20px;
    padding: 20px;
}
.demo-inline p {
    margin: 0 0;
}
.demo-inline h1 {
    color: #1976D2;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    margin: 0 0;
}
.demo-inline h2 {
    color: #1976D2;
    font-size: 2em;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 40px;
}
.demo-inline h3 {
    font-size: 1.5em;
    color: #403f42;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 40px;
}
.demo-inline ul,
.demo-inline ol {
    padding-left: 20px;
}
.demo-inline ul {
    list-style: disc;
}
.demo-inline ol {
    list-style: decimal;
}
.demo-inline a {
    text-decoration: underline;
}
.demo-inline img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 10px 10px 10px;
}
.demo-inline textarea {
    display: none;
}
.demo-inline *[contentEditable="true"]:focus,
.demo-inline *[contentEditable="true"]:hover {
    outline: 2px solid #1976D2;
}
